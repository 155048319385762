export const headerNavItems = {
    "fr": {
        name: "NewHeaderTemplate",
        menuItems: {
            nodes: [
                // {
                //     childItems: {
                //         nodes: [
                //             {
                //                 childItems: {
                //                     nodes: [],
                //                 },
                //                 id: 'cG9zdDo0NTk2',
                //                 label: 'Expérience',
                //                 url: "https://cityrelay.com/experience/",
                //             },
                //             {
                //                 childItems: {
                //                     nodes: [],
                //                 },
                //                 id: 'cG9zdDo0NjAw',
                //                 label: 'Trouvez votre maison',
                //                 url: "https://stay.cityrelay.com/property-to-rent",
                //             },
                //             {
                //                 childItems: {
                //                     nodes: [],
                //                 },
                //                 id: 'cG9zdDo0NTk4',
                //                 label: 'Ressources résidentielles',
                //                 url: "https://cityrelay.com/resources/",
                //             },
                //         ]
                //     },
                //     id: 'cG9zdDo0NTky',
                //     label: 'Pour les résidents',
                //     parentId: null,
                //     target: null,
                //     url: '#',
                // },
                {
                    childItems: {
                        nodes: [
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjMx',
                                label: 'Comment ça fonctionne',
                                url: "https://cityrelay.com/fr/pour-les-proprietaires-de-biens-immobiliers-a-paris/",
                            },
                            {
                                childItems: {
                                    nodes: [
                                        {
                                            id: 'cG9zdDo0NjM3',
                                            label: 'Gestion de la propriété',
                                            url: "https://cityrelay.com/fr/gestion-immobiliere-paris/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM2',
                                            label: 'Annonce',
                                            url: "https://cityrelay.com/fr/annonce-immobiliere-paris/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM4',
                                            label: 'Nettoyage',
                                            url: "https://cityrelay.com/fr/service-nettoyage-et-livraison-de-linge-immobilier-paris/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjMz',
                                            label: 'Service intégré',
                                            url: "https://cityrelay.com/fr/services-integres-pour-les-investisseurs-immobiliers-a-paris/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM0',
                                            label: 'Recherche de propriété',
                                            url: "https://cityrelay.com/property-sourcing/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM1',
                                            label: 'Conseil en conception',
                                            url: "https://cityrelay.com/consultancy/",
                                        },
                                    ]
                                },
                                id: 'cG9zdDo0NjEy',
                                label: 'Nos services',
                                url: "#",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjM5',
                                label: 'Évaluation locative',
                                url: "https://cityrelay.com/fr/estimation-de-location-a-paris/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo1MDA3',
                                label: 'Experts en location',
                                url: "https://cityrelay.com/fr/les-agents-de-location-a-paris/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjMy',
                                label: 'Location flexible',
                                url: "https://cityrelay.com/resources/flexible-letting-maximise-income/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0Nzkx',
                                label: 'Ressources',
                                url: "https://cityrelay.com/partner-resources/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjQx',
                                label: 'Nos partenaires',
                                url: "https://cityrelay.com/partners/",
                            },
                        ]
                    },
                    id: 'cG9zdDo0NTk0',
                    label: 'Propriétaires',
                    parentId: null,
                    target: null,
                    url: '#',
                },
                {
                    childItems: {
                        nodes: []
                    },
                    id: 'cG9zdDo0NjY3',
                    label: 'Durabilité',
                    parentId: null,
                    target: null,
                    url: "https://cityrelay.com/sustainability/",
                }
            ]
        }
    },
    "es": {
        name: "NewHeaderTemplate",
        menuItems: {
            nodes: [
                // {
                //     childItems: {
                //         nodes: [
                //             {
                //                 childItems: {
                //                     nodes: [],
                //                 },
                //                 id: 'cG9zdDo0NTk2',
                //                 label: 'Experiencia',
                //                 url: "https://cityrelay.com/experience/",
                //             },
                //             {
                //                 childItems: {
                //                     nodes: [],
                //                 },
                //                 id: 'cG9zdDo0NjAw',
                //                 label: 'Encuentra tu hogar',
                //                 url: "https://stay.cityrelay.com/property-to-rent",
                //             },
                //             {
                //                 childItems: {
                //                     nodes: [],
                //                 },
                //                 id: 'cG9zdDo0NTk4',
                //                 label: 'Recursos para residentes',
                //                 url: "https://cityrelay.com/resources/",
                //             },
                //         ]
                //     },
                //     id: 'cG9zdDo0NTky',
                //     label: 'Para residentes',
                //     parentId: null,
                //     target: null,
                //     url: '#',
                // },
                {
                    childItems: {
                        nodes: [
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjMx',
                                label: 'Cómo funciona',
                                url: "https://cityrelay.com/es/para-propietarios-barcelona/",
                            },
                            {
                                childItems: {
                                    nodes: [
                                        {
                                            id: 'cG9zdDo0NjM3',
                                            label: 'Administración de la propiedad',
                                            url: "https://cityrelay.com/es/gestion-integral-de-propiedades-barcelona/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM2',
                                            label: 'Anuncio',
                                            url: "https://cityrelay.com/es/anuncio-de-propiedades-en-barcelona/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM4',
                                            label: 'Limpieza',
                                            url: 'https://cityrelay.com/es/servicio-de-limpieza-de-propiedades-y-suministro-de-ropa-de-cama-en-barcelona/',
                                        },
                                        {
                                            id: 'cG9zdDo0NjMz',
                                            label: 'Servicio integrado',
                                            url: "https://cityrelay.com/es/servicios-integrados-en-barcelona-para-inversores-en-propiedades/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM0',
                                            label: 'Abastecimiento de propiedades',
                                            url: "https://cityrelay.com/property-sourcing/",
                                        },
                                        {
                                            id: 'cG9zdDo0NjM1',
                                            label: 'Consultoría de diseño',
                                            url: "https://cityrelay.com/consultancy/",
                                        },
                                    ]
                                },
                                id: 'cG9zdDo0NjEy',
                                label: 'Nuestros servicios',
                                url: "#",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjM5',
                                label: 'Valoración de alquiler',
                                url: "https://cityrelay.com/es/estimacion-de-alquiler-en-barcelona/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo1MDA3',
                                label: 'Expertos en alquiler',
                                url: "https://cityrelay.com/es/gestion-de-alquileres-en-barcelona/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjMy',
                                label: 'Alquiler flexible',
                                url: "https://cityrelay.com/resources/flexible-letting-maximise-income/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0Nzkx',
                                label: 'Recursos',
                                url: "https://cityrelay.com/partner-resources/",
                            },
                            {
                                childItems: {
                                    nodes: [],
                                },
                                id: 'cG9zdDo0NjQx',
                                label: 'Nuestros socios',
                                url: "https://cityrelay.com/partners/",
                            },
                        ]
                    },
                    id: 'cG9zdDo0NTk0',
                    label: 'Propietarios',
                    parentId: null,
                    target: null,
                    url: '#',
                },
                {
                    childItems: {
                        nodes: []
                    },
                    id: 'cG9zdDo0NjY3',
                    label: 'Sostenibilidad',
                    parentId: null,
                    target: null,
                    url: "https://cityrelay.com/sustainability/",
                }
            ]
        }
    }

}

export const mobileSubNavItems = {
    "fr": {
        name: "Mobile Sub Menu",
        menuItems: {
            nodes: [
                {
                    id: 'cG9zdDoyODQ4',
                    label: "À propos",
                    url: "https://cityrelay.com/about/",
                },
                {
                    id: 'cG9zdDoyODUw',
                    label: 'Carrières',
                    url: "https://cityrelay.com/careers/",
                },
                {
                    id: 'cG9zdDoyODUy',
                    label: 'Contact',
                    url: "https://cityrelay.com/contact/",
                },
                {
                    id: 'cG9zdDoyODU2',
                    label: 'Durabilité',
                    url: "https://cityrelay.com/sustainability/",
                },
                {
                    id: 'cG9zdDoyODU0',
                    label: 'FAQs',
                    url: "https://cityrelay.com/faqs/",
                },
            ]
        }
    },
    "es": {
        name: "Mobile Sub Menu",
        menuItems: {
            nodes: [
                {
                    id: 'cG9zdDoyODQ4',
                    label: "Acerca de",
                    url: "https://cityrelay.com/about/",
                },
                {
                    id: 'cG9zdDoyODUw',
                    label: 'Carreras',
                    url: "https://cityrelay.com/careers/",
                },
                {
                    id: 'cG9zdDoyODUy',
                    label: 'Contacto',
                    url: "https://cityrelay.com/contact/",
                },
                {
                    id: 'cG9zdDoyODU2',
                    label: 'Sostenibilidad',
                    url: "https://cityrelay.com/sustainability/",
                },
                {
                    id: 'cG9zdDoyODU0',
                    label: 'FAQs',
                    url: "https://cityrelay.com/faqs/",
                },
            ]
        }
    }
}

export const valuationButtonLabels ={
    "en": {
        label: "Get Valuation",
        url: "/pricing/",
    },
    "fr": {
        label: "Obtenir une estimation",
        url: "/fr/estimation-de-location-a-paris/",
    },
    "es": {
        label: "Obtener estimación",
        url: "/es/estimacion-de-alquiler-en-barcelona/",
    },
}