import React from 'react';
import RentalCta from '~/components/molecules/RentalCta';
import { StaticImage } from 'gatsby-plugin-image';
import usePageLanguage from '~/hooks/usePageLanguage';
import { rentalCtaLabels } from '~/data/translations/rentalCtaLabels';

export default function FooterRentalCta({showHelpFeatures}) {

  const pageLanguage = usePageLanguage();
  const labels = rentalCtaLabels[pageLanguage];

  return (
    <div className="lg:flex">
      <div className="mb-10 md:pr-4">
        <RentalCta subheading={labels.subheading} heading={labels.heading} subheadingClassName="text-blue" showHelpFeatures={showHelpFeatures} language={pageLanguage} />
      </div>
      <div className="-ml-6 -mr-6 lg:ml-auto mb-10 lg:mb-12 lg:-mr-40">
        <StaticImage src={'../../assets/images/footer-rental-cta.png'} className="w-full lg:w-[42rem]" alt="CityRelay" />
      </div>
    </div>
  )
}
