import { Link } from 'gatsby';
import React, { useState } from 'react'
import IconChevron from '../atoms/IconChevron';

const CitiesDropdown = ({ headerWhite, scrolled, navIsOpen, language }) => {

    const [isActive, setIsActive] = useState(false);

    const cities = [
        {
            label: 'London',
            language: 'en',
            url: '/for-property-owners/',
        },
        {
            label: 'Barcelona',
            language: 'es',
            url: '/es/para-propietarios-barcelona/',
        },
        {
            label: 'Paris',
            language: 'fr',
            url: '/fr/pour-les-proprietaires-de-biens-immobiliers-a-paris/',
        }
    ];

    const currentCity = cities.find((city) => city.language === (language ?? 'en'))

    const toggleDropdown = () => setIsActive(currentState => !currentState)

    return (
        <div className='relative w-fit p-3 tracking-tight transition-all duration-200 ease-in-out z-20 flex items-center justify-center'>
            <div
                className={`hover:opacity-50 flex items-center cursor-pointer ${navIsOpen ? 'text-navy' : (headerWhite && !scrolled) ? 'text-white' : 'text-navy'}`}
                onClick={toggleDropdown}
            >
                <p>{currentCity.label}</p>
                <IconChevron className={`ml-2 transform transition-transform duration-200 ease-in-out ${isActive ? 'rotate-180' : ''}`} />
            </div>
            {isActive && (
                <div className='bg-white px-3 py-1 rounded-md absolute top-[100%] w-full flex flex-col w-24 shadow-box'>
                    {cities.map((city, index) => (
                        <Link className='hover:opacity-50' onClick={toggleDropdown} to={city.url} key={index}>{city.label}</Link>
                    ))}
                </div>
            )}
        </div>
    )
}

export default CitiesDropdown