import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Button from '~/components/atoms/Button'
import HelpFeatures from '~/components/molecules/HelpFeatures'
import { rentalCtaLabels } from '~/data/translations/rentalCtaLabels';

export default function RentalCta({ className, subheadingClassName, subheading, heading, showHelpFeatures = false, language = 'en' }) {
  const [value, setValue] = useState('');

  const labels = rentalCtaLabels[language];

  const redirects = {
    "en": "/pricing/",
    "fr": "/fr/estimation-de-location-a-paris/",
    "es": "/es/estimacion-de-alquiler-en-barcelona/",
  }

  return (
    <div className={`${className}`}>
      <h6 className={`text-h6 mb-4 ${subheadingClassName}`}>{subheading}</h6>
      <h4 className="text-h2 mb-6">{heading}</h4>
      <form
        className="flex flex-col sm:flex-row"
        onSubmit={e => {
          e.preventDefault();
          navigate(`${redirects[language]}`);
        }}
      >
        <input value={value} onChange={e => setValue(e.target.value)} type="text" placeholder={labels.postcode} className="bg-white px-5 py-4 md:py-6 w-full outline-none text-navy postcode-input" />
        <Button type="button" title={labels.buttonLabel} className="inline-flex items-center whitespace-nowrap" />
      </form>
      {showHelpFeatures && (
        <HelpFeatures language={language ?? 'en'}/>
      )}
    </div>
  )
}
